const defaultState = {
  tickets: []
}

const getters = {
  tickets (state) {
    return state.tickets
  }
}

const mutations = {
  SET_TICKET (state, payload) {
    state.tickets = [...state.tickets, payload]
  },

  OVERWRITE_TICKET (state, payload) {
    state.tickets[payload.index] = payload.payload
  },

  REMOVE_TICKET (state, index) {
    state.tickets.splice(index, 1)
  },

  RESET_TICKET (state) {
    state.tickets = []
  }
}

const actions = {
  ADD_TICKET ({ commit, state }, payload) {
    const tickets = state?.tickets
    if (Array.isArray(tickets)) {
      const findIndex = tickets.findIndex((item) => item?.id === payload?.id)
      if (findIndex >= 0) {
        return findIndex
      }
    }
    commit('SET_TICKET', payload)
    return state?.tickets || null
  },

  OVERWRITE_TICKET ({ commit }, payload) {
    commit('OVERWRITE_TICKET', payload)
  },

  DELETE_TICKET ({ commit }, index) {
    commit('REMOVE_TICKET', index)
  },

  RESET_TICKET ({ commit }) {
    commit('RESET_TICKET')
  }

}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  mutations,
  actions
}
