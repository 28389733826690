export default [
  {
    path: '/customer',
    name: 'customer',
    component: () => import('@/views/Customers/Customer.vue'),
    meta: {
      title: 'Customer'
    }
  },
  {
    path: '/customer/add',
    name: 'customer-add',
    component: () => import('@/views/Customers/CustomerAdd.vue'),
    meta: {
      title: 'Customer Add'
    }
  },
  {
    path: '/customer/detail/:id',
    name: 'customer-detail',
    component: () => import('@/views/Customers/Components/CustomerTab.vue'),
    meta: {
      title: 'Customer Detail'
    }
  }
]
