export default [
  {
    path: '/favorite-email',
    name: 'favorite-email',
    component: () => import('@/views/Favorite-Email/FavoriteEmail.vue'),
    meta: {
      title: 'Favorite Email'
    }
  }
]
