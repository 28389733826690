<template>
  <div
    class="toastification pointer"
    @click.prevent="onClick()">
    <div class="d-flex justify-end">
      <span
        class="cursor-pointer toastification-close-icon ml-auto "
        @click="$emit('close-toast')"
      >
        <feather-icon
          v-if="!hideClose"
          icon="XIcon"
          class="text-body"
        />
      </span>
    </div>
    <div class="d-flex align-items-start">
      <b-avatar
        :variant="variant"
        size="1.8rem"
        class="mr-75 flex-shrink-0"
      >
        <feather-icon
          :icon="icon"
          size="15"
        />
      </b-avatar>
      <div class="d-flex flex-column flex-grow-1">
        <div
          v-if="customerName"
          class="mt-1">
          <div
            class="d-inline-block text-body"
            v-text="`Customer Name: ${customerName}`"
          >
          </div>
        </div>
        <div
          v-if="contactName"
          class="mt-1">
          <div
            class="d-inline-block text-body"
            v-text="`Contact Name: ${contactName}`"
          >
          </div>
        </div>
        <div>
          <h5
            v-if="title"
            class="mb-0 font-weight-bolder toastification-title"
            :class="`text-${variant}`"
            v-text="title"
          />
          <small
            v-if="text"
            class="d-inline-block text-body"
            v-text="text"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar
  },
  props: {
    variant: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    hideClose: {
      type: Boolean,
      default: false
    },
    timeout: {
      type: Number,
      default: 3600000
    },
    customerName: {
      type: String,
      default: null
    },
    contactName: {
      type: String,
      default: null
    },
    isHaveContact: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}

.pointer {
  cursor: pointer;
}
</style>
