export default [
  {
    path: '/report/ticket-detail',
    name: 'report-ticket-detail',
    component: () => import('@/views/Reports/TicketDetail.vue'),
    meta: {
      title: 'Report Ticket Detail'
    }
  },
  {
    path: '/report/summary-by-agent',
    name: 'report-summary-by-agent',
    component: () => import('@/views/Reports/SummaryByAgent.vue'),
    meta: {
      title: 'Report Summary By Agent'
    }
  },
  {
    path: '/report/summary-by-channel',
    name: 'report-summary-by-channel',
    component: () => import('@/views/Reports/SummaryByChannel.vue'),
    meta: {
      title: 'Report Summary By Channel'
    }
  },
  {
    path: '/report/summary-by-case-type',
    name: 'report-summary-by-case-type',
    component: () => import('@/views/Reports/SummaryByCaseType.vue'),
    meta: {
      title: 'Report Summary By Case Type'
    }
  },
  {
    path: '/report/ticket-detail-report',
    name: 'ticket-detail-report',
    component: () => import('@/views/Reports/TicketDetailReport.vue'),
    meta: {
      title: 'Report Ticket Detail'
    }
  },
  {
    path: '/report/ticket-detail-report-new',
    name: 'ticket-detail-report-new',
    component: () => import('@/views/Reports/TicketDetailNewReport.vue'),
    meta: {
      title: 'Report Ticket Detail (New)'
    }
  },
  {
    path: '/report/categories-report',
    name: 'categories-report',
    component: () => import('@/views/Reports/CategoiresReport.vue'),
    meta: {
      title: 'Categories Report'
    }
  },
  {
    path: '/report/download',
    name: 'report-download',
    component: () => import('@/views/Reports/DownloadReport.vue'),
    meta: {
      title: 'Download Report'
    }
  }

]
