import Vue from 'vue'

Vue.filter('formatTicketStatus', (status) => {
  switch (status) {
  case 'open':
    return 'Open'
  case 'closed':
    return 'Closed'
  case 'void':
    return 'Void'
  case 'over Due':
    return 'Over Due'
  default:
    return '-'
  }
})
