import Vue from 'vue'

Vue.filter('formatStatusData', (val) => {
  switch (val) {
  case 'Not Urgent':
    return 'Not Urgent'
  case 'Urgent':
    return 'Urgent'
  case 'Very Urgent':
    return 'Very Urgent'
  default:
    return ''
  }
})
