import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import axios from 'axios'
import { isValidToken } from '../auth/utils'
// Routes
// import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

import customers from './routes/customers'
import tickets from './routes/tickets'
import knowledges from './routes/knowledges'
import reports from './routes/reports'
import favoriteEmail from './routes/favorite-email'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        title: 'Dashboard'
        // pageTitle: 'Dashboard',
        // breadcrumb: [
        //   {
        //     text: 'Dashboard',
        //     active: true
        //   }
        // ]
      }
    },
    ...customers,
    ...tickets,
    ...knowledges,
    ...favoriteEmail,
    ...reports,
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true
          }
        ]
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true
      }
    },
    {
      path: '/cxone/auth',
      name: 'cxone-auth',
      component: () => import('@/views/CXONE/Auth.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

const publicRoute = ['login', 'cxone-auth']
// const superAdminRoute = ['ClientList', 'AdminList', 'AdminAdd']
// const nonSuperAdminRoute = ['ReportHome']

router.beforeEach(async (to, from, next) => {
  await store.restored
  const routeName = to.name

  // const role = getRole()
  // const isLoginFromCXONE = store.getters['User/isLoginFromCXONE']
  // if (isLoginFromCXONE) {
  //   const tokenExpired = store.getters['User/tokenExpired']
  //   const timeNow = Math.floor(new Date().getTime() / 1000)
  //   if (tokenExpired) {
  //     if (timeNow >= tokenExpired) {
  //       const url = process.env.VUE_APP_API_URL || 'https://brs-api.tubtimcrm.com'
  //       const refreshToken = store.getters['User/refreshToken']
  //       try {
  //         const { data } = await axios.post(`${url}/auth/cxone/token`, { refreshToken })
  //         const expired = Math.floor(new Date().getTime() / 1000) + 2400
  //         store.dispatch('User/setRefreshToken', { token: data?.data?.refresh_token })
  //         store.dispatch('User/setTokenExpired', { expired })
  //         store.dispatch('User/setCXOneToken', { token: data?.data?.access_token })
  //       } catch (error) {
  //         store.dispatch('ticket/RESET_TICKET')
  //         store.dispatch('User/resetUser')
  //         next({ name: 'login' })
  //       }
  //     }
  //   } else {
  //     store.dispatch('ticket/RESET_TICKET')
  //     store.dispatch('User/resetUser')
  //     next({ name: 'login' })
  //   }
  // }

  if (publicRoute.some((pr) => pr === routeName)) {
    next()
  } else {
    // const token = getAccessToken()
    const tokenValid = isValidToken()
    if (!tokenValid) {
      store.dispatch('ticket/RESET_TICKET')
      store.dispatch('User/resetUser')
      next({ name: 'login' })
    } else {
      next()
    }
  }
  return next()
})

// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()

//   if (!canNavigate(to)) {
//     // Redirect to login if not logged in
//     if (!isLoggedIn) return next({ name: 'login' })

//     // If logged in => not authorized
//     return next({ name: 'second-page' })
//   }

//   // Redirect if logged in
//   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//     const userData = getUserData()
//     next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
//   }

//   return next()
// })

// ? For splash screen
// Remove afterEach hook if you are not using splash screen

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
