/* eslint-disable import/no-cycle */
import AuthProvider from '@/resources/AuthProvider'

const AuthService = new AuthProvider()

const state = {
  accessToken: null,
  sessionId: null,
  refreshToken: null,
  isLoginFromCXONE: false,
  tokenExpired: null,
  cxoneToken: null,
  cxoneSkill: null,
  user: {
    id: '',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    userGroupId: '',
    userTeamId: ''
  }
}

const getters = {
  user: (state) => state.user,
  accessToken: (state) => state.accessToken,
  refreshToken: (state) => state.refreshToken,
  isLoginFromCXONE: (state) => state.isLoginFromCXONE,
  tokenExpired: (state) => state.tokenExpired,
  cxoneToken: (state) => state.cxoneToken,
  cxoneSkill: (state) => state.cxoneSkill,
  sessionId: (state) => state.sessionId

}

const mutations = {
  SET_USER (state, payload) {
    state.accessToken = payload.token
    state.user.id = payload.id
    state.user.username = payload.username
    state.user.firstName = payload.firstName
    state.user.lastName = payload.lastName
    state.user.email = payload.email
    state.user.role = payload.role
    state.user.userGroupId = payload.userGroupId
    state.user.userTeamId = payload.userTeamId
  },

  SET_REFRESH_TOKEN (state, { token }) {
    state.refreshToken = token
  },

  SET_CXONE_TOKEN (state, { token }) {
    state.cxoneToken = token
  },

  SET_CXONE_SKILL (state, { skill }) {
    state.cxoneSkill = skill
  },

  SET_CXONE_LOGIN (state, { isLogin }) {
    state.isLoginFromCXONE = isLogin
  },

  SET_TOKEN_EXPIRED (state, { expired }) {
    state.tokenExpired = expired
  },

  SET_SESSION_ID (state, { sessionId }) {
    state.sessionId = sessionId
  },

  RESET_USER (state) {
    state.isLoginFromCXONE = false
    state.cxoneToken = false
    state.cxoneSkill = null
    state.refreshToken = null
    state.accessToken = null
    state.user.id = ''
    state.user.username = ''
    state.user.firstName = ''
    state.user.lastName = ''
    state.user.email = ''
    state.user.role = ''
    state.user.userGroupId = ''
    state.user.userTeamId = ''
    state.tokenExpired = null
  }
}

const actions = {
  async signIn ({ dispatch }, payload) {
    const { data } = await AuthService.login({ username: payload.username, password: payload.password })
    if (data?.token) {
      dispatch('setUser', {
        ...data.user,
        token: data.token
      })
    }
  },

  resetUser ({ commit }) {
    commit('RESET_USER')
  },

  setUser ({ commit }, payload) {
    commit('SET_USER', payload)
  },

  setRefreshToken ({ commit }, payload) {
    commit('SET_REFRESH_TOKEN', {
      token: payload?.token
    })
  },

  setCXONELogin ({ commit }, payload) {
    commit('SET_CXONE_LOGIN', {
      isLogin: payload?.isLogin
    })
  },

  setTokenExpired ({ commit }, payload) {
    commit('SET_TOKEN_EXPIRED', {
      expired: payload?.expired
    })
  },

  setCXOneToken ({ commit }, payload) {
    commit('SET_CXONE_TOKEN', {
      token: payload?.token
    })
  },

  setCXOneSkill ({ commit }, payload) {
    commit('SET_CXONE_SKILL', {
      skill: payload?.skill
    })
  },

  setSessionId ({ commit }, payload) {
    commit('SET_SESSION_ID', {
      sessionId: payload?.sessionId
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
