import Vue from 'vue'

Vue.prototype.$statusValue = (status) => {
  switch (status) {
  case 'Not Urgent':
    return 'light-success'
  case 'Urgent':
    return 'light-warning'
  case 'Very Urgent':
    return 'light-danger'
  default:
    return ''
  }
}
