import Vue from 'vue'

Vue.prototype.$ticketStatus = (status) => {
  switch (status) {
  case 'open':
    return 'light-primary'
  case 'closed':
    return 'light-success'
  case 'void':
    return 'light-warning'
  case 'over Due':
    return 'light-danger'
  default:
    return ''
  }
}
