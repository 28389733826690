export default [
  {
    path: '/ticket',
    name: 'ticket',
    component: () => import('@/views/Tickets/Ticket.vue'),
    meta: {
      title: 'Ticket'
    }
  },
  {
    path: '/ticket/add',
    name: 'ticket-add',
    component: () => import('@/views/Tickets/components/TicketDynamicTab.vue'),
    meta: {
      title: 'Ticket Add'
    }
  },
  {
    path: '/ticket/detail/:id',
    name: 'ticket-detail',
    component: () => import('@/views/Tickets/components/TicketTabDetail.vue'),
    meta: {
      title: 'Ticket Detail'
    }
  }
]
