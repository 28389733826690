const state = {
  progressLoading: 0
}

const getters = {
  progressLoading: (state) => state.progressLoading
}

const mutations = {
  PLUS_PROGRESS (state) {
    state.progressLoading += 1
  },

  MINUS_PROGRESS (state) {
    if (state.progressLoading <= 0) {
      state.progressLoading = 0
    } else {
      state.progressLoading -= 1
    }
  }
}

const actions = {
  increaseLoading ({ commit }) {
    commit('PLUS_PROGRESS')
  },

  decreaseLoading ({ commit }) {
    commit('MINUS_PROGRESS')
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
