/* eslint-disable import/no-cycle */
import HttpRequest from './HttpRequest'

class AuthProvider extends HttpRequest {
  login (payload) {
    return this.post('/auth/login', payload)
  }

  signInCXOne (sessionToken) {
    return this.post('/auth/cxone', { sessionToken })
  }

  signInCXOneWithCode (code) {
    return this.post('/auth/cxone', { code })
  }
}

export default AuthProvider
