import Vue from 'vue'

Vue.prototype.$formatStatusValue = (status) => {
  switch (status) {
  case 'active':
    return 'light-success'
  case 'inactive':
    return 'light-warning'
  case 'deleted':
    return 'light-danger'
  default:
    return ''
  }
}
