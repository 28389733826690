export default [
  {
    path: '/knowledge',
    name: 'knowledge',
    component: () => import('@/views/Knowledges/Knowledge.vue'),
    meta: {
      title: 'Knowledge'
    }
  },
  {
    path: '/knowledge/add',
    name: 'knowledge-add',
    component: () => import('@/views/Knowledges/KnowledgeAdd.vue'),
    meta: {
      title: 'Knowledge Add'
    }
  },
  {
    path: '/knowledge/detail/:id',
    name: 'knowledge-detail',
    component: () => import('@/views/Knowledges/KnowledgeUpdate.vue'),
    meta: {
      title: 'Knowledge Update'
    }
  }

]
